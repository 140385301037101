















import { Component, Vue } from "vue-property-decorator";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";

@Component({
    components: {
        PageTitleHeader
    }
})
export default class PresentersView extends Vue {}
